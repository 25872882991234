@import 'css/_vars.scss';


.form-field {
	padding: 0 0 20px 0;
	
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	
	input[type="text"], input[type="password"] {
		width: 200px;
		padding: 0 5px;
		font-size: inherit;
		line-height: 28px;
		text-align: center;
		
		border: none;
		border-bottom: 2px $dark-teal solid;
		background-color: #0000;
		
		-webkit-transition: border-color 0.2s ease-out;
		transition: border-color 0.2s ease-out;
		
		&:focus {
			outline: none;
			border-color: $light-teal;
		}
	}

	input[type="text"]:-webkit-autofill,
	input[type="text"]:-webkit-autofill:hover, 
	input[type="text"]:-webkit-autofill:focus,
	input[type="password"]:-webkit-autofill,
	input[type="password"]:-webkit-autofill:hover, 
	input[type="password"]:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		border-bottom: 1px $light-teal solid;
		-webkit-text-fill-color: $copy-grey;
		-webkit-box-shadow: 0 0 0px 1000px #0000 inset;
		transition: background-color 5000s ease-in-out 0s;
	}
	
	.field-error {
		color: $red;
		line-height: 28px;
		font-size: 14px;
	}
}

@import 'css/_vars.scss';


.form-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.non-field-error {
	color: $red;
	margin-top: 10px;
	margin-bottom: 20px;
}


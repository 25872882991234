.default-btn {
  width: 160px;
  cursor: pointer;
  user-select: none;
  border: 2px solid $dark-teal;
  border-radius: 6px;
  padding: 10px 15px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: $copy-grey;
  background-color: #00000000;
  margin: 20px auto 10px auto;
  
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  
  &:hover {
    border-color: $light-teal;
    background-color: #ffffff07;
  }
  
  &.disabled {
    cursor: not-allowed;
    border-color: #666;
    color: #666;
    opacity: 0.8;
    
    &:hover {
      border-color: #666;
      background-color: #00000000;
    }
  }
}

.cancel-btn {
  width: 75px;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 20px;
  border: 1px $red solid;
  border-radius: 6px;
  color: $red;
  font-size: 14px;
  opacity: 0.7;
  text-align: center;
  
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
  
  &:hover {
    color: $red-hover;
    border-color: $red-hover;
  }
}

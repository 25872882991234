@import 'css/_vars.scss';


.nav {
    @media (max-width: 549px) {
        display: none;
    }
    
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    border-bottom: 2px solid $very-dark-teal;
    
    a {
        text-decoration: none;
    }
    
    .nav-btn {
        cursor: pointer;
        user-select: none;
        color: $dark-teal;
	    position: relative;
		text-align: center;
        margin-bottom: -2px;
        padding: 10px 15px 12px 15px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        -webkit-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;
        
        &:before {
            content: "";
            position: absolute;
            left: 51%;
            right: 51%;
            bottom: 0;
            background: $light-teal;
            height: 2px;
            -webkit-transition: left 0.2s ease-out, right 0.2s ease-out;
            transition: left 0.2s ease-out, right 0.2s ease-out;
        }
        
        &:hover, &.active {
            color: $light-teal;
        }
        
        &:hover:before, &:focus:before, &:active:before, &.active:before {
            left: 0;
            right: 0;
        }
    }
    
    .spacer {
        flex: 1;
    }
    
}

.mobile-nav {
    @media (min-width: 550px) {
        display: none;
    }
    
    position: fixed;
    bottom: 0;
    z-index: 999;
    
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
    height: 80px;
    border-top: 2px solid $light-teal;
    background-color: $very-dark-teal;
    
    a {
        text-decoration: none;
    }
    
    .nav-btn {
        cursor: pointer;
        user-select: none;
        height: 60px;
        padding: 8px 20px;
        
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;
        
        .mobile-nav-icon {
			overflow: visible;
			fill: $dark-teal;
			stroke: $dark-teal;
			stroke-width: 1px;
			height: 28px;
			width: 28px;
            
            transition: fill 0.3s ease-out, stroke 0.3s ease-out;
            -webkit-transition: fill 0.3s ease-out, stroke 0.3s ease-out;
        }
        
        .mobile-nav-label {
            color: $dark-teal;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;
            
            transition: color 0.3s ease-out;
            -webkit-transition: color 0.3s ease-out;
        }
        
        &.active, &:hover {
            .mobile-nav-icon {
    			fill: $light-teal;
    			stroke: $light-teal;
            }
            .mobile-nav-label {
                color: $light-teal;
            }
        }
        
    }
}

.mobile-more-menu {
    @media (min-width: 550px) {
        display: none;
    }
    
    position: fixed;
    bottom: 0;
    z-index: 999;
    
    width: 100%;
    
    // height of mobile-nav is 80px.
    // margin-bottom here should equal that height.
    // Then height here is however tall you want the more menu to slide up to be.
    // Make sure you also adjust the mobileNavVariants in nav.js
    height: 100px;
    margin-bottom: 80px;
    
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    
    a {
        text-decoration: none;
    }
    
    .more-menu-item {
        cursor: pointer;
        user-select: none;
        color: $dark-teal;
        padding: 10px 20px;
        margin: 5px;
        border: 1px $dark-teal solid;
        
        transition: color 0.3s ease-out, border-color 0.3s ease-out;
        -webkit-transition: color 0.3s ease-out, border-color 0.3s ease-out;
        
        &.active, &:hover {
            color: $light-teal;
            border-color: $light-teal;
        }
    }
    
}


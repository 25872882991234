@import 'css/_vars.scss';


.stocks-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	
	.not-authed {
		margin-top: 40px;
	}

	.stocks-pane {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		margin-bottom: 40px;
		
		.page-title {
			padding: 0;
			margin-bottom: 20px;
		}
		
		.ticker-add-form {
			margin-bottom: 15px;
			
			.form-field {
				padding-bottom: 5px;
			}
			
			input {
				width: 150px;
			}
			
			&.enabled {
				input {
					// background-color: #00ff00;
				}
			}
			&.disabled {
				input {
					// background-color: #00000066;
				}
			}
		}
		
		.market-state {
			width: 140px;
			margin-bottom: 10px;
			font-size: 16px;
			
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			
			span:first-child {
				margin-right: 10px;
				color: #ffffffaa;
			}
			span:last-child {
				margin-left: 10px;
				font-weight: 600;
				text-transform: capitalize;
			}
			
		}
		
		.market-countdown {
			margin-bottom: 15px;
			font-size: 14px;
			color: #aaa;
		}
		
		.updated-ts {
			margin-bottom: 4px;
			font-size: 20px;
			font-weight: 600;
		  cursor: pointer;
			line-height: 18px;
			padding: 2px;
		}
					
		.market-stats-title {
			margin-bottom: 8px;
			font-size: 12px;
			color: #aaa;
		}
		
		.tickers-data {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			
			.ticker-entry {
				display: flex;
				flex-flow: row wrap;
			
				width: 300px;
				padding: 16px;
				background-color: #ffffff00;
				
				outline: 1px $dark-teal solid;
				margin-top: 1px;
			  margin-left: 1px;
				
				-webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out, transform 0.3s ease-out, outline-color 0.3s ease-out;
				transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out, transform 0.3s ease-out, outline-color 0.3s ease-out;
				
				.ticker {
					width: 40%;
					font-size: 28px;
					text-align: center;
				}
				
				.summary {
					width: 60%;
					text-align: center;
					
					.ticker-current-price {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-evenly;
						
						.current-price {
							font-size: 28px;
						}
						.price-changes {
							display: flex;
							flex-flow: column nowrap;
							
							.change-dollars {
								font-size: 13px;
							}
							.change-percent {
								font-size: 13px;
							}
						}
					}
					.ticker-fetching {
						font-size: 14px;
					}
				}
				
				.alert {
					width: 100%;
					margin-top: 5px;
					font-size: 12px;
					color: #aaa;
					min-height: 15px;
				}
				
				.market-stats {
					width: 100%;
					margin-top: 8px;
					
					display: flex;
					flex-flow: row wrap;
					
					.market-stats-item {
						margin-top: 5px;
						font-size: 14px;
						opacity: 0.88;
						
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;
						
						&.half {
							width: 50%;
						}
						&.full {
							width: 100%;
						}
						
						span:first-child {
							margin-left: 10px;
							color: #ffffffaa;
						}
						span:last-child {
							margin-right: 10px;
						}
					}
				}
				
				.extra-details {
					width: 100%;
					margin-top: 10px;
					display: flex;
					flex-flow: column nowrap;
					align-items: center;
					text-align: center;
					color: #aaa;
					
					.company-name {
						width: 100%;
						font-size: 14px;
					}
					.exchange-info {
						width: 100%;
						font-size: 11px;
					}
				}
				
			}
			
		}
		
	}

	// @keyframes wave {
	//   50% {
	// 	// transform: scale(.5);
	// 	opacity: 1;
	//   }
	// }
		
	.config-pane {
		position: absolute;
		margin: 0 20px;
		padding-left: calc(100vw - 100%);
		
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		
		@media (max-width: 375px) {
			margin: 0 10px;
			left: 0;
		}
		
		.config-title {
			font-size: 32px;
			margin-bottom: 15px;
		}
		
		.general-config {
			margin-bottom: 40px;
			
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
		}
		
		.config-input {
			width: 100%;
			margin-bottom: 5px;
			
			label, input {
				transition: opacity 0.2s ease-out;
			}
			&.disabled {
				label, input {
					opacity: 0.2;
					cursor: default !important;
				}
				.speaker-icon {
					cursor: default !important;
					svg {
						fill: $dark-teal !important;
					}
				}
			}
			
			.speaker-icon {
				cursor: pointer;
				
				svg {
					overflow: visible;
					fill: $dark-teal;
					stroke: #00000000;
					stroke-width: 4px;
					height: 20px;
					width: 20px;
					position: relative;
					top: 4px;
					left: 10px;
					
					transition: fill 0.2s ease-out;
					-webkit-transition: fill 0.2s ease-out;
				}
				
				&:hover {
					svg {
						fill: $light-teal;
					}
				}
				
				&.disabled {
					opacity: 0.2;
					cursor: default !important;
				}
			}
			
			&.config-input-text {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				
				padding: 10px;
				
				label {
					user-select: none;
					font-size: 18px;
					margin-left: 10px;
				}
				
				input[type="text"] {
					width: 90px;
				}
			}
			
			&.config-input-checkbox {
				input {
					position: absolute;
					visibility: hidden;
				}
				
				label {
					user-select: none;
					cursor: pointer;
					padding: 10px;
					border-radius: 6px;
					
					-webkit-transition: background-color 0.2s ease-out;
					transition: background-color 0.2s ease-out;
					
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;
					
					&:hover {
						background-color: rgba($dark-teal, 0.2);
						
						.checkbox-box {
							border-color: $light-teal;
						}
					}
					
					.checkbox-box {
						width: 14px;
						height: 14px;
						padding: 3px;
						border: 1px #aaa solid;
						border-radius: 6px;
						margin-left: 40px;
						stroke: none;
						fill: #ffffff00;
						-webkit-transition: border-color 0.2s ease-out, background-color 0.2s ease-out, fill 0.2s ease-out;
						transition: border-color 0.2s ease-out, background-color 0.2s ease-out, fill 0.2s ease-out;
					}
					.checkbox-label {
						font-size: 18px;
					}
				}
				
				&.disabled {
					label {
						background-color: unset;
						.checkbox-box {
							border-color: #aaa;
						}
					}
				}
				
				input:checked + label {
					.checkbox-box {
						background-color: $light-teal;
						border-color: $light-teal;
						fill: #fff;
					}
				}
			}
		}
		
		.ticker-configs {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin-bottom: 160px;
			
			.ticker-config-section {
				width: 276px;
				padding: 20px 30px;
				background-color: #ffffff00;
				
				outline: 1px $dark-teal solid;
				margin-top: 1px;
			    margin-left: 1px;
				
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				
				@media (max-width: 350px) {
					width: 90%;
			    }
				
				.ticker-title {
					font-size: 26px;
					font-weight: 500;
					margin-bottom: 15px;
				}
				
				.remove-ticker-btn {
					user-select: none;
					cursor: pointer;
					padding: 5px 10px;
					margin-top: 20px;
					border: 1px $red solid;
					border-radius: 6px;
					color: $red;
					font-size: 14px;
					opacity: 0.7;
					
					-webkit-transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
					transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
					
					&:hover {
						color: $red-hover;
						border-color: $red-hover;
						background-color: #3c1d1d9e;
					}
				}
				
			}
		}
		
		.alerts-unsupported {
			font-size: 14px;
			color: #aaa;
			margin-bottom: 10px;
		}
		
	}

	// Save/cancel buttons that show up in config mode
	.config-sticky-footer {
	    z-index: 9999;
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		border-top: 6px $light-teal solid;
		background-color: #3c4c5f;
		padding: 5px 0;
		
		display: flex;
		justify-content: space-around;
		
		.footer-btn {
			user-select: none;
			cursor: pointer;
			text-align: center;
			width: 100px;
			padding: 10px;
			margin: 10px;
			border: 1px #aaa solid;
			border-radius: 8px;
			color: $copy-grey;
			font-size: 20px;
			font-weight: 500;
			
			-webkit-transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			
		}
		
		.save {
			background-color: $grey-bg;
			
			&:hover {
				border-color: $green;
				color: $green;
			}
		}
		
		.cancel {
			background-color: $grey-bg;
			
			&:hover {
				border-color: $red;
				color: $red;
			}
		}
	}

	.base-icon-btn {
		position: absolute;
		cursor: pointer;
		padding: 5px;
		margin: 5px;
		
		.inner-icon {
			overflow: visible;
			fill: #00000000;
			stroke: $dark-teal;
			stroke-width: 1px;
			height: 32px;
			width: 32px;
			
			transition: stroke 0.2s ease-out;
			-webkit-transition: stroke 0.2s ease-out;
		}
		
		&:hover {
			.inner-icon {
				stroke: $light-teal;
			}
		}
	}

	.sort-btn {
		@extend .base-icon-btn;
		top: 68px;
		left: 10%;
		
		@media (max-width: 549px) {
			top: 30px;
			left: 5%;
		}

		.inner-icon {
			height: 36px;
			width: 36px;
			stroke-width: 1.5px;
		}
	}

	.config-btn {
		@extend .base-icon-btn;
		top: 68px;
		right: 10%;
		
		@media (max-width: 549px) {
			top: 30px;
			right: 5%;
		}
	}

	.green {
		color: $green;
	}

	.red {
		color: $red;
	}

	// Pop up menu for sorting tickers
	.sort-menu {
		position: absolute;
		z-index: 99;
		top: 68px;
		width: 170px;
		margin: 12px 0 0 60px;
		border: 2px solid $light-teal;
    border-radius: 6px;
    background-color: $grey-bg;
		
		.sort-option {
			cursor: pointer;
			user-select: none;
			padding: 10px 20px;
			height: 24px;
			
			-webkit-transition: background-color 0.2s ease-out;
			transition: background-color 0.2s ease-out;
			
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			
			&:hover {
				background-color: rgba($dark-teal, 0.2);
				
				.checkbox-box {
					border-color: $light-teal;
				}
			}

			.indicator {
				margin-left: 20px;

				.selected-icon {
					overflow: visible;
					fill: $dark-teal;
					height: 24px;
					width: 24px;
				}
			}

		}

		&.visible {
			left: 10%;
			opacity: 1;
			transition: opacity 0.2s;

			@media (max-width: 549px) {
				top: 30px;
				left: 5%;
			}

		}
		&.hidden {
			left: -4000px;
			opacity: 0;
			transition: opacity 0.2s, left 0s 0.2s;

			@media (max-width: 549px) {
				top: 30px;
			}
		}
	}

}

.tickers-empty, .app-disabled-warning {
	margin-top: 25px;
	text-align: center;
}

@import 'css/_vars.scss';
@import 'css/_buttons.scss';


.games-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	
	.page-sub-title {
		text-align: center;
		font-size: 13px;
		padding: 10px 0;
	}
	
	.game-list {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		
		.game-block {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			
			width: 90%;
			max-width: 380px;
			padding: 7px;
			margin: 25px 60px;
			font-size: inherit;
			cursor: pointer;
			color: $dark-teal;
			background-color: #ffffff00;
			border: 2px $dark-teal solid;
			border-radius: 5px;
			
			-webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
			transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
			
			@media (max-width: 500px) {
				margin: 25px 10px;
			}
			
			&:hover {
				background-color: #ffffff08;
				border-color: $light-teal;
				color: $light-teal;
			}
			&:focus {
				outline: none;
			}
			
			.title {
				font-size: 24px;
				font-weight: 600;
			}			
			.image {
				margin: 15px 0;
				height: 64px;
				image-rendering: pixelated;
				
				img {
					height: 100%;
				}
			}			
			.description {
				text-align: center;
				font-size: 15px;
			}
		}
	}
	
	.big-play-btn {
		@extend .default-btn;
		font-weight: 800;
		font-size: 24px;
		width: 250px;
	}
	
	.small-leaderboard {
		width: 100%;
		max-width: 900px;
		margin-top: 20px;
	}
	
	.big-fade-title {
		text-align: center;
		font-size: 64px;
		color: $light-teal;
		font-weight: 700;
		letter-spacing: 3px;
		opacity: 0.85;
		border-bottom: 3px dashed $dark-teal;
		
		&.top-pad {
			margin-top: 70px;
		}
	}
	
	.current-project-content {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		margin-top: 50px;
		
		@media (max-width: 767px) {
			flex-flow: row wrap;
		}
		
		.left-pic {
			max-width: 350px;
			
			img {
				width: 100%;
				border: 3px solid #fff;
				border-radius: 10px;
			}
		}
		
		.right-content {
			max-width: 600px;
			margin-left: 40px;
			
			@media (max-width: 767px) {
				display: flex;
				flex-flow: column nowrap;
				align-items: center;
				text-align: center;
				margin: 20px 0 0 0;
			}
			
			.title {
				font-size: 38px;
				font-weight: 600;
				margin-bottom: 15px;
			}
		}
		
	}
}

.status-up, .good-text {
	color: $green;
}

.status-down, .bad-text {
	color: $red;
}

.status-unknown, .warning-text {
	color: #aaa;
}

.unsupported-device {
	margin: 30px 0;
	border: 2px $yellow dashed;
	padding: 40px 60px;
	color: $yellow;
	text-align: center;
	
	.unsupported-device-title {
		font-size: 30px;
	}
	.unsupported-device-body {
		margin-top: 15px;
	}
}
@import '_vars.scss';
@import '_buttons.scss';
@import '_layout.scss';
@import '_text.scss';

// 480, 768, 1024, 1280

html, body {
  margin: 0;
  min-height: 100%;
  background-color: $grey-bg;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app, input, select {
  color: $copy-grey;
}

.app {
  display: flex;
  flex-flow: column nowrap;
  font-size: 16px;
}

.app-content {
  margin: 40px 20px 0 20px;
  
  @media (max-width: 549px) {
    margin-bottom: 80px;
  }
}

@import 'css/_vars.scss';


.leaderboards-page {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	
	.title {
		font-size: 50px;
		font-weight: 700;
		text-align: center;
		margin-top: 20px;
	}
	
	.toggle-buttons {
		text-align: center;
		
		button {
			margin: 30px 20px 0 20px;
		}
	}
	
	.all-leaderboards {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		margin-top: 40px;
		
		.leaderboard-section {
			position: absolute;
			width: 96%;
			max-width: 1200px;
			padding-bottom: 80px;
			
		}
	}
}

.leaderboard-container {
	width: 100%;
	
	.leaderboard {
		.title {
			font-size: 32px;
			font-weight: 700;
			text-align: center;
			margin: 20px 0;
			
			span {
				border-bottom: 1px solid #6c9ab5ad;
				padding: 0 20px 5px 20px;
			}
		}
		
		.table-loading {
			text-align: center;
		}
		
		.table {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			margin-top: 35px;
			
			.table-header {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-around;
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 10px;
				
				.header-col {
					text-align: center;
					width: 150px;
				}
			}
			
			.table-row {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-around;
				align-items: center;
				height: 60px;
				border-bottom: 2px solid #6c9ab5ad;
			    background-color: #00000022;
				
				.col {
					text-align: center;
					width: 150px;
					overflow: hidden;
				    text-overflow: ellipsis;
					
					&:first-child {
						font-size: 30px;
						font-weight: 700;
					}
				}
				
			}
			
			.rank-1-row {
				@extend .table-row;
				font-size: 38px;
				font-weight: 600;
				height: 150px;
				
				.col {
					&:first-child {
						font-size: 80px;
					}
				}
			}
		}
	}
	
	// Define custom column widths per type of leaderboard
	.total_exp_gained .table, .boss_kills .table {
		.table-header .header-col, .table-row .col {
			&:nth-child(1) {width: 15%;}
			&:nth-child(2) {width: 45%;}
			&:nth-child(3) {width: 25%;}
			&:nth-child(4) {width: 15%;}
		}
	}
	
	.refresh-countdown {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		
		div {
			margin: 10px 2px;
			opacity: 0.6;
		}
	}
	
}

.panel-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	
	.panel-content {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		
		table {
			margin: 20px auto 0 auto;
			
			th, td {
				text-align: center;
				padding: 5px 0;
				min-width: 120px;
			}
		}
	}
}

pre {
	white-space: pre-wrap;
}

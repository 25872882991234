.default-page-container {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
  
  .page-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .page-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    max-width: 992px;
    text-align: center;
  }
  
  .form-header {
    margin-bottom: 45px;
    text-align: center;
    font-size: 18px;
  }
  
  .form-footer {
    margin-top: 40px;
    text-align: center;
    font-size: 15px;
  }
}

@import 'css/_vars.scss';


.user-settings {
	.section {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		width: 100%;
		max-width: 400px;
		margin: 0 auto 50px auto;
		
		.title {
			font-size: 22px;
			color: $light-teal;
			border-bottom: 1px solid #6c9ab5;
			margin-bottom: 5px;
			padding: 0 5px;
		}
		
		.field {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 100%;
			padding: 5px;
			
		}
	}

	@media (max-width: 549px) {
		.logout-btn {
			display: none;
		}
	}
}
	
.small-text {
  font-size: 13px;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.red {
  color: $red;
}

a {
  text-decoration: underline;
  color: $light-teal;
  transition: color 0.3s ease-out;
  
  &:hover {
    color: $dark-teal;
  }
}

// COLOURS

$grey-bg: #2a2d31;
$very-dark-teal: #334254; // (nav border bottom)
$dark-teal: #6c9ab5;
$light-teal: #6ecdf3;
$copy-grey: #eeeeee;
$green: #44c365;
$yellow: #e6d343;
$red: #b55858;
$red-hover: #f75555;
